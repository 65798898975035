<template>
  <div>
    <PopupModal v-if="popupEnabled"></PopupModal>
    <BotManWidgetHandler />
    <SomministraTCEmergenzaSuspendedSurvey  
    ></SomministraTCEmergenzaSuspendedSurvey>
    <div class="w-sm-100 d-md-flex">
      <section class="nav-desktop" :style="{ 'z-index': menuZindex }">
        <NavigationCittadino :wlistener="innerWidth" />
      </section>

      <section class="main-operatore">
        <CittadinoTopHeader />
        <div class="px-4 pt-2">
          <router-view> </router-view>
        </div>
      </section>
      <template v-if="$root.scope === 'concura'">
        <CallSupportModal
          :onlysupport="true"
          style="position: fixed; bottom: 21px; right: 97px"
        />
        <CallSupportModal />
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import NavigationCittadino from "@/app/layout/components/NavigationCittadino.vue";
import BotManWidgetHandler from "@/app/common/components/utilities/BotManWidgetHandler.vue";
import CallSupportModal from "@/app/layout/components/CallSupportModal.vue";
import CittadinoTopHeader from "@/app/layout/components/CittadinoTopHeader.vue";
import PopupModal from "@/app/modules/statistiche/components/PopupModal.vue";
import SomministraTCEmergenzaSuspendedSurvey from "@/app/modules/servizi/teleconsultoEmergenza/components/SomministraTCEmergenzaSuspendedSurvey.vue"


export default {
  name: "CittadinoMain",
  components: {
    NavigationCittadino,
    BotManWidgetHandler,
    CallSupportModal,
    CittadinoTopHeader,
    PopupModal,
    SomministraTCEmergenzaSuspendedSurvey,
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      popupEnabled: process.env.VUE_APP_POPUP_ENABLED,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState("modal", ["closable", "open", "canNavigate"]),
    menuZindex: function () {
      if (this.open && this.canNavigate) {
        return 2040;
      }
      return 5;
    },
  },
  methods: {
    innerWidthChanged() {
      // console.log("resize handler");
      this.innerWidth = window.innerWidth;
    },
  },
  created() {
    if (this.user.type !== 0) {
      this.$router.push({ name: "Home" });
    }

    // this.setupWidgetVariables()
    window.addEventListener("resize", this.innerWidthChanged);
  },
  mounted() {
    if (
      this.user.expired_password == 1 &&
      this.$route.name !== "ImpostazioniCittadinoMain"
    ) {
      this.$router.push({
        name: "ImpostazioniCittadinoMain",
        query: { scope: "expired" },
      });
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>
