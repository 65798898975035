<template>
  <div>
    <div v-if="!started">
      <p class="d-flex justify-content-center mb-0">
        <b-button
          variant="secondary font-weight-bold text-center"
          class="mx-auto my-2"
          @click="start"
          >Ok, Procedi</b-button
        >
      </p>
    </div>
    <div v-else>
      <b-tabs
        align="center"
        active-nav-item-class="text-secondary border-0 d-none"
        active-tab-class="text-dark"
        content-class="mt-3"
        nav-class="border-0 text-white d-none"
        no-key-nav
        v-model="questIndex"
      >
        <template v-for="(domanda, ind) in domande">
          <template v-if="!domanda.si && !domanda.hi">
            <b-tab title :key="ind" title-item-class="disabledTab">
              <div class="mx-auto my-4">
                <h5 class="">
                  <span class="">{{ questionIndex(ind) }}.</span>
                  {{ domanda.q }}
                </h5>
                <br />
                <b-form-group :key="'fgrc_' + ind">
                  <div
                    v-if="domanda.o && domanda.o === 'file'"
                    class="align-self-center"
                  >
                    <SurveyUploader
                      v-on:upload-result="handleUpload($event, ind)"
                      v-on:remove-result="removeUpload(ind)"
                      :accepts="domanda.fa"
                      :isMulti="domanda.fm"
                    />
                  </div>
                  <b-form-checkbox-group
                    v-else-if="domanda.cb && domanda.o"
                    :name="'q_' + ind"
                    v-model="domanda.a"
                    :options="domanda.o"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                  <div v-else-if="domanda.o">
                    <b-button
                      v-for="(opzione, indx) in domanda.o"
                      :key="indx"
                      class="col-lg-3 col-sm-4 mx-1 my-2 fixed__h text__control"
                      :variant="
                        domande[ind].a === opzione
                          ? 'secondary'
                          : 'outline-secondary'
                      "
                      @click="selectOption(domanda, opzione, ind)"
                      >{{ opzione }}</b-button
                    >
                  </div>
                  <b-form-textarea
                    v-capitalize-first
                    v-else
                    v-model="domanda.a"
                    placeholder="Scrivi qui"
                    class="my-2 col-10 mx-auto"
                  >
                  </b-form-textarea>
                  <b-form-input
                    v-else
                    v-capitalize-first
                    class="align-self-center"
                    placeholder="Scrivi qui"
                    type="text"
                    :name="'q_' + ind"
                    v-model="domanda.a"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <b-row class="justify-content-center">
                <b-button
                  v-if="ind > 0"
                  variant="secondary"
                  class="mx-2"
                  @click="questIndex--"
                  >Torna indietro</b-button
                >
                <b-button
                  variant="secondary"
                  class="mx-2"
                  @click="isLast ? finalizza() : next()"
                  :disabled="
                    domanda.r !== undefined &&
                    domanda.r === true &&
                    (domanda.a === null || domanda.a === '')
                  "
                  >Procedi</b-button
                >
                <b-button
                  v-if="domanda.r !== undefined && domanda.r === false"
                  variant="secondary"
                  class="mx-2"
                  @click="skip(ind)"
                  >Salta</b-button
                >
              </b-row>
              <br />
            </b-tab>
          </template>
          <template
            v-else-if="
              domanda.si && domande[domanda.si.index].a === domanda.si.response
            "
          >
            <template
              v-if="
                !domanda.hi ||
                (domanda.hi &&
                  domande[domanda.hi.index].a != domanda.hi.response)
              "
            >
              <b-tab title :key="ind" title-item-class="disabledTab">
                <div class="mx-auto my-4">
                  <h5 class="">
                    <span class="">{{ questionIndex(ind) }}.</span>
                    {{ domanda.q }}
                  </h5>
                  <br />
                  <b-form-group :key="'fgrc_' + ind">
                    <div
                      v-if="domanda.o && domanda.o === 'file'"
                      class="align-self-center"
                    >
                      <SurveyUploader
                        v-on:upload-result="handleUpload($event, ind)"
                        v-on:remove-result="removeUpload(ind)"
                        :accepts="domanda.fa"
                        :isMulti="domanda.fm"
                      />
                    </div>
                    <b-form-checkbox-group
                      v-else-if="domanda.cb && domanda.o"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                      :options="domanda.o"
                      name="flavour-1"
                    ></b-form-checkbox-group>
                    <div v-else-if="domanda.o">
                      <template v-if="domanda.o.length <= 9">
                        <b-button
                          v-for="(opzione, indx) in domanda.o"
                          :key="indx"
                          class="col-lg-3 col-sm-4 mx-1 my-2 fixed__h text__control"
                          :variant="
                            domande[ind].a === opzione
                              ? 'secondary'
                              : 'outline-secondary'
                          "
                          @click="selectOption(domanda, opzione, ind)"
                          >{{ opzione }}</b-button
                        >
                      </template>
                      <template v-else>
                        <b-form-select
                          v-capitalize-first-select
                          v-model="domanda.a"
                          :name="'qc_' + ind"
                          :required="domanda.r !== undefined ? domanda.r : true"
                          class="col-lg-10 col-12"
                        >
                          <b-form-select-option
                            v-for="opt in domanda.o"
                            :key="opt"
                            :value="opt"
                            >{{ opt }}
                          </b-form-select-option>
                          <!-- <b-form-select-option :value="null" disabled>seleziona</b-form-select-option> -->
                        </b-form-select>
                      </template>
                    </div>
                    <b-form-textarea
                      v-capitalize-first
                      v-else
                      v-model="domanda.a"
                      placeholder="Scrivi qui"
                      class="my-2 col-10 mx-auto"
                    >
                    </b-form-textarea>
                    <b-form-input
                      v-else
                      v-capitalize-first
                      class="align-self-center"
                      placeholder="Scrivi qui"
                      type="text"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-row class="justify-content-center">
                  <b-button
                    variant="secondary"
                    class="mx-2"
                    @click="questIndex--"
                    >Torna indietro</b-button
                  >
                  <b-button variant="secondary" class="mx-2" @click="next"
                    >Procedi</b-button
                  >
                  <b-button
                    v-if="domanda.r !== undefined && domanda.r === false"
                    variant="secondary"
                    class="mx-2"
                    @click="skip(ind)"
                    >Salta</b-button
                  >
                </b-row>
                <br />
              </b-tab>
            </template>
          </template>
        </template>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
import SurveyUploader from "@/app/common/components/utilities/SurveyUploader.vue"
export default {
  name: "FillSurvey",
  props: ["survey", "hascore"],
  components: { SurveyUploader },
  data() {
    return {
      questIndex: 0,
      started: false,
      domande: null,
      file: null,
      uploading: false,
      btnText: "Salva Immagine",
      questionario: null,
      sending: false
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    isLast() {
      return this.questIndex === this.domande.length - 1
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    start() {
      this.started = true
    },
    selectOption(domanda, opzione, ind) {
      domanda.a = opzione
    },
    next() {
      this.questIndex++
    },
    skip(ind) {
      this.domande[ind].a = null
      this.questIndex++
    },
    finalizza() {
      this.sending = true
      this.$emit("end-quest", this.domande)
    },
    handleUpload(data, ind) {
      if (this.domande[ind].a == null) {
        this.domande[ind].a = []
      }
      this.domande[ind].a.push(data.hashid)
      if (Array.isArray(data)) {
        this.domande[ind].a = data.map((el) => el.hashid)
      }
    },
    removeUpload(ind) {
      this.domande[ind].a = null
    },
    questionIndex(i) {
      var index = i + 1
      return index + "/" + this.domande.length
    }
  },
  created() {
    this.questionario = JSON.parse(this.survey)
    this.domande = this.questionario.domande
    if (this.questionario.info == null) {
      this.start()
    }
    // console.log(this.domande);
  }
}
</script>
<style scoped>
.fixed__h {
  height: 10vh;
}

.text__control {
  white-space: initial;
  word-wrap: break-word;
}

.nav-tabs {
  background-color: transparent !important;
  display: none !important;
}

li > a:hover {
  border-color: transparent !important;
  background-color: transparent !important;
  cursor: none !important;
}

ul[role="tablist"] {
    display: none !important;
}

.disabledTab {
    display:none !important;
}
</style>
