<template>
  <b-row
    class="header__shadow bg-secondary text-white p-4 mb-3 align-items-center justify-content-between"
  >
    <h1 class="pl-2 pl-lg-4 py-2 py-lg-3 py-xl-4">
      {{ routeLabel }}
    </h1>
    <b-col class="text-right">
      <h5 class="ml-auto mr-lg-4 mt-2 mt-lg-3 text-capitalize">
        <span class="mr-2 fs-1"> {{ currentTime }}</span> {{ currentDate }}
      </h5>
      <template
        v-if="
          user.company.level !== 5 &&
          activeRefs.includes(10) &&
          companyHasService(10)
        "
      >
        <OperatorServiceStatusSwitch
          v-if="user.permessi.includes('gestire teleconsulto di emergenza')"
          :serviceref="10"
        ></OperatorServiceStatusSwitch>
        <PopupNotificationComponent></PopupNotificationComponent>
      </template>
    </b-col>
    <!-- <div class="ml-auto text-right d-flex align-items-center">
      <h5 class="mr-2">{{ currentTime }}</h5>
      <h5>{{ currentDate }}</h5> 
    </div> -->
  </b-row>
</template>
<script>
import { OPERATORE_ROUTE_NAMES } from "@/_utils/constants.js";
import { mapState } from "vuex";
import OperatorServiceStatusSwitch from "@/app/modules/impostazioni/components/utente/OperatorServiceStatusSwitch.vue";
import PopupNotificationComponent from "@/app/layout/components/PopupNotificationComponent.vue";

export default {
  name: "OperatoreTopHeader",
  components: { OperatorServiceStatusSwitch, PopupNotificationComponent },
  methods: {
    updateTime() {
      const now = new Date();
      this.currentTime = now.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      this.currentDate = now.toLocaleDateString(undefined, {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
    companyHasService(ref) {
      if (this.company) {
        return this.company.active_services.find(
          (el) => el.position_ref === ref
        )
          ? true
          : false;
      }
      return false;
    },
  },
  computed: {
    ...mapState("company", ["company"]),
    ...mapState("utente", ["status", "user"]),
    ...mapState("settings", ["activeRefs", "userSubscriptionNeeds"]),
    welcomeName() {
      if (this.user && this.user.anagrafica) {
        return `${this.user.anagrafica.name} ${this.user.anagrafica.surname}`;
      }
      return this.user.username;
    },
    routeLabel() {
      try {
        const label = this.routeNames[this.$route.name];
        if (label === undefined) {
          throw "label not found";
        } else if (this.$route.name === "teleconsulto") {
          return label + " " + this.$route.params.section;
        }
        return label;
      } catch (err) {
        return `Benvenuto su ${this.appName}`;
      }
    },
  },
  data() {
    return {
      routeNames: OPERATORE_ROUTE_NAMES,
      currentTime: "",
      currentDate: "",
      appName: process.env.VUE_APP_APP_NAME,
    };
  },
  mounted() {
    this.updateTime();
    setInterval(this.updateTime, 1000); // Aggiorna l'orario ogni secondo
  },
};
</script>
